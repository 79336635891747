import React, { Fragment, useState, useEffect } from "react";
import { HashLink } from 'react-router-hash-link';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from '../Title'
import './style.scss'

// images
import teamMember1 from '../../images/expert/1.jpeg'
import teamMember2 from '../../images/expert/2.jpeg'
import teamMember3 from '../../images/expert/3.jpeg'
import teamMember4 from '../../images/expert/4.jpeg'
import teamMember5 from '../../images/expert/white1.png'
import teamMember6 from '../../images/expert/6.jpg'
import teamMember7 from '../../images/expert/7.jpeg'

const teams = [
    {
        name: 'Hau Pehn Yapp', level: 'Founder/Principal Lawyer', image: teamMember1, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer1",
    },
    {
        name: 'Moon Xu', level: 'Practice Manager', image: teamMember2, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer2",
    },
    {
        name: 'Mark Gustavsson', level: 'Special Counsel / Litigation Lawyer', image: teamMember7, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer5",
    },
    {
        name: 'Jingru Cui', level: 'Lawyer', image: teamMember3, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer3",
    },
    // {
    //     name: 'Yao Huang', level: 'Lawyer', image: teamMember4, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ], lawyerid: "lawyer4",
    // },
    {
        name: 'Yuri Wang', level: 'Legal Assistant', image: teamMember5, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer7",
    },
    // {
    //     name: 'TBA', level: 'Lawyer', image: teamMember5, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ], lawyerid: "lawyer8",
    // },
    {
        name: 'TBA', level: 'Registered Conveyancer', image: teamMember5, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer6",
    }


]

const teamsCn = [
    {
        name: '叶浩鹏', level: '创始人 / 首席律师', image: teamMember1, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer1",
    },
    {
        name: '许晖', level: '运营经理', image: teamMember2, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer2",
    },
    {
        name: 'Mark Gustavsson', level: '法律顾问 / 诉讼律师', image: teamMember7, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer5",
    },
    {
        name: '崔静茹', level: '律师', image: teamMember3, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer3",
    },
    // {
    //     name: '黄瑶', level: '律师', image: teamMember4, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ], lawyerid: "lawyer4",
    // },
    {
        name: 'Yuri Wang', level: '法律助理', image: teamMember5, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer7",
    },
    // {
    //     name: '即将公布', level: '律师', image: teamMember5, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ], lawyerid: "lawyer8",
    // },
    {
        name: '即将公布', level: '过户师', image: teamMember5, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ], lawyerid: "lawyer6",
    },
]

const teammm = localStorage.getItem("language") != "cn" ? teams : teamsCn



const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const TeamMember = ({ className, title, subTitle, slider, noGutters }) => {
    return (
        <div className={className} id="team">
            <div className="container">
                <div className={!noGutters ? 'row' : 'row no-gutters'}>
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {slider ? (
                        <div className="col-12">
                            <Slider className="teamSlideArea" {...settings}>
                                {teammm.map((team, i) => (
                                    <HashLink to={"about#" + team.lawyerid}>
                                        <div key={i} className="teamWrapper">
                                            <div className="teamImage">
                                                <img src={team.image} alt="" />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span>{team.level}</span>
                                                <ul>
                                                    {/* {team.socialMedia.map(social => (
                                                    <li key={social}><a href="#"><i className={`fa fa-${social}`} aria-hidden="true"></i></a></li>
                                                ))} */}
                                                </ul>
                                            </div>
                                        </div></HashLink>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                        <Fragment>
                            {teammm.slice(0, 4).map((team, i) => (
                                <div key={i} className="col-lg-3 col-md-3 col-12">
                                    <HashLink to={"about#" + team.lawyerid}>
                                        <div className="teamWrapper">
                                            <div className="teamImage">
                                                <img src={team.image} alt="" />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span>{team.level}</span>
                                                <ul>
                                                    {/* {team.socialMedia.map(social => (
                                                        <li key={social}><a href="#"><i className={`fa fa-${social}`} aria-hidden="true"></i></a></li>
                                                    ))} */}
                                                    <br />
                                                </ul>
                                            </div>

                                        </div>
                                    </HashLink>

                                </div>
                            ))}
                            {teammm.slice(4, 8).map((team, i) => (
                                <div key={i} className="col-lg-3 col-md-3 col-12">
                                    <HashLink to={"about#" + team.lawyerid}>
                                        <div className="teamWrapper">
                                            <div className="teamImage">
                                                <img src={team.image} alt="" />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span>{team.level}</span>
                                                <ul>
                                                    {/* {team.socialMedia.map(social => (
                                                        <li key={social}><a href="#"><i className={`fa fa-${social}`} aria-hidden="true"></i></a></li>
                                                    ))} */}
                                                    <br />
                                                </ul>
                                            </div>

                                        </div>
                                    </HashLink>
                                </div>
                            ))}
                            {/* {teammm.slice(6, 9).map((team, i) => (
                                <div key={i} className="col-lg-4 col-md-4 col-12">
                                    <HashLink to={"about#" + team.lawyerid}>
                                        <div className="teamWrapper">
                                            <div className="teamImage">
                                                <img src={team.image} alt="" />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span>{team.level}</span>
                                                <ul>
                                                    <br />
                                                </ul>
                                            </div>

                                        </div>
                                    </HashLink>
                                </div>
                            ))} */}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}
export default TeamMember